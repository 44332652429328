import { renderDatatable } from "../application"

columns = [
  { data: "job_title" },
  { data: "job_type" },
  { data: "location" },
  { data: "source" },
  { data: "published_date" },
  {
    data: "expired",
    render: function(data, _) {
      if (data === 'true') {
        return `<span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-emerald-50 text-emerald-700 ring-emerald-700/10">Yes</span>`
      } else {
        return `<span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-red-50 text-red-700 ring-red-600/10">No</span>`
      }
    },
    searchable: false,
    orderable: false
  },
  {
    data: "embedding",
    render: function(data, _) {
      if (data === 'true') {
        return `<span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-emerald-50 text-emerald-700 ring-emerald-700/10">Yes</span>`
      } else {
        return `<span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-red-50 text-red-700 ring-red-600/10">No</span>`
      }
    },
    searchable: false,
    orderable: false
  },
  { data: "created_at" },
  { data: "action", searchable: false, orderable: false }
]

order = [[7, "desc"]]

renderDatatable($('#admin-jobs-datatable'), columns, order)
