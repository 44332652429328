import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "submitButton", "checkbox", "radioButton", "textArea", "textAreaProgress", "progressText" ]
  static values = {
    step: String
  }

  connect() {
    if (this.stepValue === "1") {
      this.checkboxSelected();
    } else if (this.stepValue === "2") {
      this.radioButtonSelected();
    } else if (this.stepValue === "3") {
      this.checkboxSelected();
    } else {
      this.textAreaFilled();
    }
  }

  disableButton() {
    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.classList.add("btn-disabled");
  }

  enableButton() {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.classList.remove("btn-disabled");
  }

  checkboxSelected() {
    if (!this.hasCheckboxTarget) return;

    const anyChecked = this.checkboxTargets.some(checkbox => checkbox.checked);

    if (anyChecked) {
      this.enableButton();
    } else {
      this.disableButton();
    }
  }

  radioButtonSelected() {
    if (!this.hasRadioButtonTarget) return;

    this.enableButton();
  }

  textAreaFilled() {
    if (!this.hasTextAreaTarget) return;

    const inputLength = this.textAreaTarget.value.trim().length
    let color = "bg-gray-200"
    let progressText;

    if (inputLength < 100) {
      color = "bg-red-500"
      progressText = this.progressTextTarget.dataset.textShort.replace("%{count}", 100 - inputLength)
      this.textAreaProgressTarget.style.width = `${Math.min(inputLength/2, 50)}%`
      this.disableButton();
    } else if (inputLength < 150) {
      color = "bg-orange-500"
      progressText = this.progressTextTarget.dataset.textGood
      this.textAreaProgressTarget.style.width = `${Math.min(inputLength/2, 75)}%`
      this.enableButton();
    } else {
      color = "bg-green-500"
      progressText = this.progressTextTarget.dataset.textGreat
      this.textAreaProgressTarget.style.width = `${Math.min(inputLength/2, 100)}%`
      this.enableButton();
    }

    this.textAreaProgressTarget.className = `h-2 rounded transition-all duration-300 ${color}`
    this.progressTextTarget.textContent = progressText
  }
}
